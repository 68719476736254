<app-section-header [title]="pageTitle"></app-section-header>

<div class="section-wrapper">

    <mat-card class="item-edit" *ngIf="notice; else noNoticeTemplate">

        <div class="row">

            <div class="col-lg-3 col-md-4 col-12" style="text-align: center;">
                <img [src]="noticeForm.get('imagePath').value" class="img-thumbnail" (click)="inputImageProfile.click()">
                <input hidden type="file" #inputImageProfile (change)="uploadImage($event)">
                <hr>
                <span (click)="inputImageProfile.click()" class="small">Clica en la imagen para cambiar la foto</span>
            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <form [formGroup]="noticeForm" (ngSubmit)="onSaveForm()">

                    <mat-card-content>

                        <fieldset *ngIf="noticeForm.controls.alerted.value && noticeForm.controls.active.value && (noticeForm.controls.status.value === 'VISIBLE')" class="alert marked-row text-center mt-3">
                            <span>Este aviso actualmente se muestra como alerta</span>
                        </fieldset>

                        <fieldset class="form-status-fields" [ngClass]="noticeForm.controls.status.value">
                            <legend>Estado del Aviso</legend>

                            <div class="row">

                                <mat-form-field class="col-12" appearance="outline">
                                    <mat-label>Estado</mat-label>

                                    <mat-select formControlName="status" [value]="noticeForm.get('status')">
                                        <mat-option *ngFor="let status of STATUS" [value]="status">
                                            {{status}}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                                <mat-slide-toggle class="col-12 col-md-3" formControlName="active">Aviso activo</mat-slide-toggle>

                                <mat-slide-toggle class="col-12 col-md-3" formControlName="alerted">Es Alerta</mat-slide-toggle>


                                <mat-slide-toggle class="col-12 col-md-3" formControlName="focused">Aviso en portada</mat-slide-toggle>

                                <div class="buttons col-12 col-md-2">
                                    <button [disabled]="notice.status !== 'VISIBLE'" mat-fab class="btn-1" (click)="gotoItem()">
                                      VER
                                    </button>
                                </div>

                                <div class="help-container alert" [ngClass]="{
                                  'alert-warning': (notice.status === 'EDITING'),
                                  'alert-danger': (notice.status === 'BLOCKED')
                                }" *ngIf="notice.status !== 'VISIBLE'">
                                    💡 El botón VER está deshabilitado porque el aviso no está guardado como estado
                                    <B>VISIBLE</B>.
                                </div>

                            </div>

                        </fieldset>


                        <fieldset class="form-info-fields">
                            <legend>Información del Aviso</legend>

                            <mat-form-field appearance="outline">
                                <mat-label>Título del aviso</mat-label>
                                <input matInput type="text" formControlName="name" placeholder="Título">
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Categorías</mat-label>
                                <mat-select formControlName="categories" multiple>
                                    <mat-option *ngFor="let category of CATEGORIES" [value]="category">{{category}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Descripción</mat-label>
                                <textarea matInput formControlName="description" placeholder="Descripción"></textarea>
                            </mat-form-field>

                            <div *ngIf="noticeForm.controls.description.value">
                                <span class="title">Así se visualizaría la Descripción:</span>
                                <div class="preview" [innerHTML]="noticeForm.controls.description.value"></div>
                            </div>

                        </fieldset>

                    </mat-card-content>

                    <mat-card-actions class="buttons">

                        <button mat-fab class="btn-1" [title]="noticeForm.valid ? 'Guardar con los datos introducidos' : 'Dishabilitado hasta que los datos sean válidos'" [disabled]="!noticeForm.valid" type="submit">
                            <mat-icon>check</mat-icon>
                        </button>

                        <button mat-fab class="btn-1" (click)="gotoList()">
                            <mat-icon>list</mat-icon>
                        </button>

                    </mat-card-actions>

                </form>

            </div>
        </div>

    </mat-card>

    <div *ngIf="errorMessage" class="alert-danger">
        {{ errorMessage }}
    </div>

</div>
<ng-template #noNoticeTemplate>
    No existe tal aviso
</ng-template>
