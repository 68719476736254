<span class="section-subtitle">{{ title }}</span>

<div *ngIf="dataSource?.data?.length > 0; else noEventsTemplate">

    <div class="section-wrapper">

        <!-- LIST View -->
        <div *ngIf="viewMode === 'list'">

            <div class="section-list-options">
                <mat-form-field>
                    <mat-label>Filtro</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <div class="buttons" *ngIf="addEvent">
                    <button mat-mini-fab class="btn-1" (click)="addItem()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Id.</th>
                        <td mat-cell class="td-extra-lg" *matCellDef="let event">{{ event.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>ESTADO</th>
                        <td mat-cell [class]="event.status" class="td-extra-lg" *matCellDef="let event">
                            <div class="inside">{{ event.status | status }}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="timestamp">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef mat-sort-header>Actualizado</th>
                        <td mat-cell class="td-extra-lg" *matCellDef="let event">hace {{ event.timestamp }}</td>
                    </ng-container>

                    <ng-container matColumnDef="timestampDetailed">
                        <th mat-header-cell class="td-audit" *matHeaderCellDef mat-sort-header>Última Modificación</th>
                        <td mat-cell class="td-audit" *matCellDef="let event">
                            <div *ngIf="event.auditLastItem">
                                <div class="inside"></div>
                                <div style="font-size: smaller;color:#303030">#️⃣​ {{ event.auditItems?.length }} modificaciones</div>
                                <div style="font-size: smaller;color:gray">
                                    🕑 {{ event.auditLastItem?.timestamp | date:'EEEE, d \'de\' MMMM hh:mm' }}
                                </div>
                                <div style="font-size: smaller;color:gray">
                                    🗃️ ​hace {{ event.timestamp }}
                                </div>
                                <div style="font-size: smaller;color:#808080">😀 {{ event.auditLastItem?.userName }}</div>
                                <div style="font-size: smaller;color:#303030">🟡 {{ event.auditLastItem?.description }}</div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="image">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <img (click)="gotoItem(element)" [src]="element.imagePath" class="img-thumbnail" alt="element.name">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="collapsed-info">
                        <th mat-header-cell class="td-collapsed-info" *matHeaderCellDef mat-sort-header>Info del Evento</th>
                        <td mat-cell class="td-collapsed-info" [class]="event.status" *matCellDef="let event" (click)="gotoItemConfig(event)">
                            <div class="inside"></div>
                            <div style="font-size: smaller;color:gray">
                                {{ event.dateIni | date:'EEEE, d \'de\' MMMM' }}
                            </div>
                            {{ event.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef mat-sort-header>Nombre</th>
                        <td mat-cell class="td-extra-lg" *matCellDef="let event">

                            <div>
                                <div class="inside"></div>
                                <div style="font-size: smaller;font-weight:bolder;text-transform:uppercase;color:black">
                                    {{ event.name }}
                                </div>
                                <div style="font-size: smaller;color:#808080">
                                    {{ ( event.eventMode ? '⚙️ ​' : '' ) + (event.eventMode | eventMode) }}
                                </div>
                                <div style="font-size: smaller;font-weight:bolder;text-transform:uppercase;color:red">​{{ event.fixed ? '📌 Fijado' : '' }}</div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="categories">
                        <th mat-header-cell class="td-extra-md" *matHeaderCellDef mat-sort-header>Categorías</th>
                        <td mat-cell class="td-extra-md" *matCellDef="let event">{{ event.description }}</td>
                    </ng-container>

                    <ng-container matColumnDef="dateIni">
                        <th mat-header-cell class="td-extra-md" *matHeaderCellDef mat-sort-header>Inicio</th>
                        <td mat-cell class="td-extra-md" *matCellDef="let event">{{ event.dateIni | date:'EEEE, d \'de\' MMMM' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="event-entities">
                        <th mat-header-cell class="td-extra-md" *matHeaderCellDef mat-sort-header>Entidades</th>
                        <td mat-cell class="td-extra-md" *matCellDef="let event">

                            <div class="col-list-container" *ngFor="let entity of event.entityItems">
                                <div class="col-list-item">

                                    <button *ngIf="(event.entityMain?.id !== entity.id); else isMainTemplate" class="item-button" mat-icon-button matTooltip="Click to View" class="iconbutton" (click)="setMain(event, entity, true)">
                                        <mat-icon class="btn-1" aria-label="View">
                                          radio_button_unchecked
                                        </mat-icon>
                                    </button>

                                    <ng-template #isMainTemplate>
                                        <button class="item-button" mat-icon-button matTooltip="Click to View" class="iconbutton" (click)="setMain(event, entity, false)">
                                          <mat-icon class="btn-1" aria-label="View">
                                            radio_button_checked
                                          </mat-icon>
                                      </button>
                                    </ng-template>


                                    <img class="item-image" [src]="entity.imagePath" alt="entity.name">

                                    <div class="item-texts">
                                        <span class="item-name">
                                            {{entity.name}}
                                        </span>

                                        <span class="item-desc">
                                            {{entity.description}}
                                        </span>
                                    </div>

                                </div>
                            </div>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="entities-info">
                        <th mat-header-cell class="td-extra-md" *matHeaderCellDef mat-sort-header>Entidades JSON</th>
                        <td mat-cell class="td-extra-md" *matCellDef="let event">
                            <pre>
                               {{ event.entityItems | json }}
                            </pre>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="entity-main">
                        <th mat-header-cell class="td-extra-md" *matHeaderCellDef mat-sort-header>Principal Entidad</th>
                        <td mat-cell class="td-extra-md" *matCellDef="let event">{{ event.entityMain ?? 'no hay' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="auditCreation">
                        <th mat-header-cell class="td-audit" *matHeaderCellDef mat-sort-header>Creación</th>
                        <td mat-cell class="td-audit" *matCellDef="let event">
                            <div *ngIf="event.auditCreation">
                                <div class="inside"></div>
                                <div style="font-size: smaller;color:gray">
                                    🕑 {{ event.auditCreation?.timestamp | date:'EEEE, d \'de\' MMMM hh:mm' }}
                                </div>
                                <div style="font-size: smaller;color:#808080">😀 {{ event.auditCreation?.userName }}</div>
                                <div style="font-size: smaller;color:#303030">🟢 {{ event.auditCreation?.name }}</div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions1">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef></th>
                        <td mat-cell class="td-extra-lg" *matCellDef="let event">

                            <button [disabled]="event.status !== 'VISIBLE'" mat-icon-button matTooltip="Click to View" class="iconbutton" (click)="gotoItem(event)">
                            <mat-icon class="btn-1" aria-label="View">
                                remove_red_eye
                            </mat-icon>
                        </button>

                            <button mat-icon-button matTooltip="Click to quit from favourite" class="iconbutton" (click)="removeFav(event)">
                            <mat-icon class="btn-1" aria-label="Favourite off">
                                star
                            </mat-icon>
                        </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions4">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef></th>
                        <td mat-cell class="td-extra-lg" *matCellDef="let event">

                            <button [disabled]="event.status !== 'VISIBLE'" mat-icon-button matTooltip="Click to View" class="iconbutton" (click)="gotoItem(event)">
                                  <mat-icon class="btn-1" aria-label="View">
                                      remove_red_eye
                                  </mat-icon>
                              </button>

                            <button mat-icon-button matTooltip="Click to Config" class="iconbutton" (click)="gotoItemConfig(event)">
                                  <mat-icon class="btn-1" aria-label="Config">
                                      build_circle
                                  </mat-icon>
                              </button>

                            <button [disabled]="( event.status === 'VISIBLE' ) || ( event.status === 'DELETED' )" mat-icon-button matTooltip="Click to Delete" class="iconbutton" (click)="deleteItem(event)">
                                <mat-icon class="btn-1" aria-label="Delete">
                                      delete
                                </mat-icon>
                            </button>

                            <button [disabled]="event.status !== 'DELETED'" mat-icon-button matTooltip="Click to Remove" class="iconbutton" (click)="deleteForeverItem(event)">
                                <mat-icon class="btn-1" aria-label="Delete Forever">cancel</mat-icon>
                            </button>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="social">
                        <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Social</th>
                        <td mat-cell class="td-extra-lg" *matCellDef="let event">
                            {{ event.extra }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>

            </div>

        </div>
        <!-- LIST View - End -->


        <!-- CARDS View -->
        <div *ngIf="viewMode === 'cards'">
            <div class="form-group">
                <input type="text" class="form-control mb-4" placeholder="Filtrar Búsqueda" [(ngModel)]="query" id="listSearch">
            </div>

            <div class="section-cards">
                <div class="item-card" *ngFor="let event of dataSource.data | searchFilter: query">

                    <app-event-card [event]="event"></app-event-card>

                </div>
            </div>
        </div>
        <!-- CARDS View - End -->

    </div>

</div>

<ng-template #noEventsTemplate>
    <div class="m-3 alert alert-warning events-container">
        <div class="events-text">
            <div class="events-text__title">Aún no tienes eventos marcados como favoritos</div>
            <div class="events-text__expl">Si estás interesado en seguir algún evento, pulsa la estrella que aparece junto a él</div>
        </div>
    </div>
</ng-template>