<div class="header-container">

    {{ title }}

    <div class="header-buttons">

        <button *ngIf="showOptionsMode" mat-mini-fab class="btn-1 header-button" [matMenuTriggerFor]="menuOptions" aria-label="Opciones de Visualización">
              <mat-icon>toggle_on</mat-icon>
        </button>

        <button *ngIf="showStatusFilter" mat-mini-fab class="btn-1 header-button" [matMenuTriggerFor]="menuStatus" aria-label="Estados Visualizados">
            <mat-icon>filter_alt</mat-icon>
        </button>

        <button *ngIf="showViewMode" mat-mini-fab class="btn-1 header-button" [matMenuTriggerFor]="menu1" aria-label="Modo de visualización">
            <mat-icon>tune</mat-icon>
        </button>

    </div>

    <mat-menu #menuStatus="matMenu" class="header-menu">
        <section class="section-status">
            <span class="section-status_title">
                Estados Mostrados:
            </span>
            <span class="section-status_list">
                <ul>
                  <li *ngFor="let status of STATUS_LIST">
                    <mat-checkbox [(ngModel)]="status.selected"
                                  (ngModelChange)="setStatusFiltered()">
                      {{status.name}}
                    </mat-checkbox>
                  </li>
                </ul>
            </span>
        </section>
    </mat-menu>


    <mat-menu #menuOptions="matMenu" class="header-menu">

        <mat-slide-toggle disabled (click)="setOption($event)" [(ngModel)]="showNoActive">
            Mostrar no Activos
        </mat-slide-toggle>

        <mat-slide-toggle (click)="setOption($event)" [(ngModel)]="showNoFavorite">
            Mostrar no Favoritos
        </mat-slide-toggle>

        <button class="menu_submit_button" mat-raised-button (click)="emitOptionsMode()">Guardar Preferencias</button>

    </mat-menu>

    <mat-menu #menu1="matMenu" class="header-menu">
        <button mat-menu-item (click)="setViewMode('cards')">
          <mat-icon>grid_view</mat-icon>
          <span>Ver como tarjetas</span>
      </button>
        <button mat-menu-item (click)="setViewMode('list')">
          <mat-icon>view_list</mat-icon>
          <span>Ver como lista</span>
      </button>
    </mat-menu>

</div>