<app-section-header [title]="'Hoy en Rincón de Soto'"></app-section-header>

<div class="sections__container">

    <!-- INI Section Alerted Notice -->
    <div *ngIf="theAlertedNotice$ | async as alertedNotice" class="section-alerted-notice alerted-notice-background">

        <div class="section__title alerted-notice-header">
            <span class="section__title--icon">🔔</span>
            <span class="section__title--text">Hay activado un aviso importante</span>
        </div>

        <div class="alert">
            <app-notice-expansion-panel [notice]="alertedNotice"></app-notice-expansion-panel>
        </div>

    </div>
    <!-- FIN Section Alerted Notice -->


    <!-- INI Section FrontPage -->
    <div class="section-frontpage">
        <mat-tab-group mat-align-tabs="center">

            <div *ngIf="nextStories$ | async as nextStories">
                <mat-tab label="📡" *ngIf="nextStories.length > 0">
                    <div class="nextStories">
                        <div class="section__title">
                            <span class="section__title--icon">📡</span>
                            <span class="section__title--text">{{ (nextStories.length === 1) ? "Próximo evento destacado en portada" : "Próximos " + nextStories.length + " eventos destacados en portada" }}</span>
                        </div>
                        <app-stories-panel [stories]="nextStories"></app-stories-panel>
                    </div>
                </mat-tab>
            </div>

            <div *ngIf="fixedStories$ | async as fixedStories">
                <mat-tab label="📌" *ngIf="fixedStories.length > 0">
                    <div class="fixedStories">
                        <div class="section__title">
                            <span class="section__title--icon">📌</span>
                            <span class="section__title--text">{{ (fixedStories.length === 1) ? "Evento fijado en portada" : fixedStories.length + " eventos fijados en portada" }}</span>
                        </div>
                        <app-stories-panel [stories]="fixedStories"></app-stories-panel>
                    </div>
                </mat-tab>
            </div>

            <div *ngIf="lastMemories$ | async as lastMemories">
                <mat-tab label="🔗​" *ngIf="lastMemories.length > 0">
                    <div class="lastMemories">
                        <div class="section__title">
                            <span class="section__title--icon">🔗​</span>
                            <span class="section__title--text">{{ (lastMemories.length === 1) ? "Artículo publicado" : lastMemories.length + " artículos publicados" }}</span>
                        </div>
                        <app-stories-panel [memories]="lastMemories"></app-stories-panel>
                    </div>
                </mat-tab>
            </div>

        </mat-tab-group>
    </div>
    <!-- FIN Section FrontPage -->


    <!-- INI Section Calendar -->
    <div class="section-calendar">
        <div class="section__title">
            <span class="section__title--icon">📆</span>
            <span class="section__title--text">Hoy es {{ viewDate | date:'EEEE, d \'de\' MMMM \'de\' YYYY' }}</span>
        </div>
    </div>

    <app-calendar [showSectionHeader]="false" [showHeader]="true" [showFooter]="true"></app-calendar>
    <!-- FIN Section Calendar -->

</div>



<ng-template #loading>
    <div>
        Cargando eventos en la agenda...
    </div>
</ng-template>

<ng-template #noEventsTemplate>
    <div class="alert alert-warning div-no-events">
        No hay eventos para este día
    </div>
</ng-template>
