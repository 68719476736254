<div *ngIf="eventsRef.length > 0; else noEventRefsTemplate" class="items__container">

    <div *ngFor="let eventRef of eventsRef; index as i">

        <section *ngIf="eventRef" class="item">

            <!--
            <pre>
                {{ eventRef | json }}
            </pre>
            -->

            <div class="item-image" href="#">
                <img class="square img-thumbnail" [src]="eventRef?.imagePath">
            </div>



            <div class="item-body" (click)="gotoEventRef(eventsRef)">

                <div class="item-admin-buttons" *ngIf="modeAdmin === true">

                    <button class="iconbutton" mat-icon-button matTooltip="Eliminar" (click)="deleteEventRef(eventRef)">
                        <mat-icon class="btn-1" aria-label="Eliminar">
                            remove_circle_outline
                        </mat-icon>
                    </button>

                    <button class="iconbutton" mat-icon-button matTooltip="Editar" (click)="editEventRef(eventRef)">
                        <mat-icon class="btn-1" aria-label="Editar">
                            edit
                        </mat-icon>
                    </button>

                    <button [disabled]="i === 0" mat-icon-button matTooltip="Subir orden" class="iconbutton" (click)="changeOrderElement(eventRef, -1)">
                      <mat-icon class="btn-1" aria-label="Subir Orden">
                          arrow_circle_up
                      </mat-icon>
                    </button>

                    <button [disabled]="i === ( eventsRef.length - 1 )" mat-icon-button matTooltip="Bajar orden" class="iconbutton" (click)="changeOrderElement(eventRef, 1)">
                        <mat-icon class="btn-1" aria-label="Bajar Orden">
                            arrow_circle_down
                        </mat-icon>
                    </button>

                </div>


                <div class="item-heading-audit">{{ eventRef?.name }}</div>

                <div class="item-title-audit">
                    <span class="icon">📅</span>​ {{ eventRef.dateIni | date:'EEEE, d \'de\' MMMM' }} | {{ eventRef.timeIni }}
                </div>

                <div *ngIf="eventRef.place" class="item-data">
                    <span class="icon">📍</span> {{ eventRef.place?.name }}
                </div>

                <div *ngIf="eventRef.eventId" class="item-link" (click)="gotoEventRef(eventRef)">
                    <span class="icon">🔎</span>
                    <span class="text">Visitar el evento</span>
                </div>

                <div *ngIf="eventRef.description" class="item-description" [innerHTML]="eventRef.description">
                    <span class="icon"></span> <span [innerHTML]="eventRef.description"></span>
                </div>




            </div>

        </section>

    </div>

</div>

<ng-template #noEventRefsTemplate>
    No hay elementos asociados
</ng-template>
