<div class="section-wrapper">

    <!-- INI Simple Event -->
    <mat-card class="item-view" *ngIf="subEvent">

        <div *ngIf="event.status === 'VISIBLE'; else noVisibleTemplate" class="row">
            <div class="col-lg-3 col-md-4 col-12" style="text-align: center;">
                <app-event-image-detail [imagePath]="subEvent.imagePath"></app-event-image-detail>
            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <mat-card-content class="item-view">

                    <fieldset class="type-social">

                        <!--
                        <legend>¡Comparte!</legend>
                        -->

                        <div class="buttons">

                            <!-- <img [src]="'assets/svg/twitter.svg'" (click)="shareLink('twitter')" class="share-button"> -->
                            <!-- <img [src]="'assets/svg/facebook.svg'" (click)="shareLink('facebook')" class="share-button"> -->
                            <img [src]="'assets/svg/whatsapp.svg'" (click)="shareLink('whatsapp')" class="share-button">

                            <span class="share-button">
                            </span>

                            <app-event-social [event]="subEvent" [userLogged]="userLogged"></app-event-social>

                        </div>

                    </fieldset>

                    <fieldset>

                        <app-event-basic-detail [event]="subEvent"></app-event-basic-detail>

                    </fieldset>

                    <fieldset class="type-other">

                        <legend>¿Cuándo?</legend>

                        <app-event-appointment-detail *ngIf="appointmentSubEvent" [showImage]="true" [appointment]="appointmentSubEvent"></app-event-appointment-detail>

                    </fieldset>

                    <fieldset class="type-other" *ngIf="subEvent?.place">

                        <legend>¿Dónde?</legend>

                        <sh-base-item-view [baseItem]="subEvent?.place"></sh-base-item-view>

                    </fieldset>

                </mat-card-content>


            </div>
        </div>

        <span class="section-title">🧩 Este evento forma parte de...</span>

    </mat-card>
    <!-- END Simple Event -->




    <!-- INI Complex Event -->
    <mat-card class="item-view" *ngIf="event; else loadingTemplate">

        <div *ngIf="event.status === 'VISIBLE'; else noVisibleTemplate" class="row">
            <div class="col-lg-3 col-md-4 col-12" style="text-align: center;">
                <app-event-image-detail [imagePath]="event.imagePath"></app-event-image-detail>
            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <mat-card-content class="item-view">

                    <fieldset class="type-social">

                        <!--
                      <legend>¡Comparte!</legend>
                      -->

                        <div class="buttons">

                            <!-- <img [src]="'assets/svg/twitter.svg'" (click)="shareLink('twitter')" class="share-button"> -->
                            <!-- <img [src]="'assets/svg/facebook.svg'" (click)="shareLink('facebook')" class="share-button"> -->
                            <img [src]="'assets/svg/whatsapp.svg'" (click)="shareLink('whatsapp')" class="share-button">

                            <span class="share-button">
                          </span>

                            <app-event-social [event]="event" [userLogged]="userLogged"></app-event-social>

                        </div>

                    </fieldset>

                    <fieldset>

                        <!--
                      <legend>¿Qué?</legend>
                      -->

                        <app-event-basic-detail [event]="event"></app-event-basic-detail>

                    </fieldset>

                    <fieldset class="type-other">

                        <legend>¿Cuándo?</legend>

                        <app-event-appointment-detail *ngIf="appointment$ | async as appointment" [showImage]="true" [appointment]="appointment"></app-event-appointment-detail>

                    </fieldset>

                    <!-- AS NOT SUPEREVENT, THE EVENT CONTAINS SCHEDULE/SESSIONS -->
                    <div *ngIf="event.eventMode === EVENT_MODE_SPLITTED">
                        <fieldset class="type-other" *ngIf="event.scheduleItems?.length > 0">

                            <legend>Sesiones</legend>

                            <sh-base-items-list [baseItems]="event.scheduleItems" [baseItemSelected]="idEventUrl"></sh-base-items-list>

                        </fieldset>
                    </div>

                    <!-- AS SUPEREVENT, THE EVENT CONTAINS EVENTS-REF -->
                    <div *ngIf="event.eventMode === EVENT_MODE_SUPEREVENT">
                        <fieldset class="type-other" *ngIf="event.eventsRef?.length > 0">

                            <legend>Programación</legend>

                            <sh-events-ref-list [eventsRef]="event.eventsRef"></sh-events-ref-list>

                        </fieldset>
                    </div>


                    <fieldset class="type-other" *ngIf="event.entityItems?.length > 0">

                        <legend>¿Quiénes?</legend>

                        <sh-base-items-list [baseItems]="event.entityItems" [userLogged]="userLogged"></sh-base-items-list>

                    </fieldset>

                    <fieldset class="type-other" *ngIf="event.placeItems?.length > 0">

                        <legend>¿Dónde?</legend>

                        <sh-base-items-list [baseItems]="event.placeItems"></sh-base-items-list>

                    </fieldset>

                    <fieldset class="type-other" *ngIf="linksItemInfo.length > 0">

                        <legend>Más info</legend>

                        <sh-base-items-list [baseItems]="linksItemInfo" [baseType]="'LINK'" [modeAdmin]="false">
                        </sh-base-items-list>

                    </fieldset>

                    <fieldset class="type-other" *ngIf="linksItemReport?.length > 0">

                        <legend>Cómo fue</legend>

                        <sh-base-items-list [baseItems]="linksItemReport" [baseType]="'LINK'" [modeAdmin]="false">
                        </sh-base-items-list>

                    </fieldset>

                </mat-card-content>

                <mat-card-actions class="buttons">

                    <button *ngIf="configAllowed" mat-fab class="btn-1" (click)="configItem()">
                      <mat-icon>build</mat-icon>
                  </button>

                </mat-card-actions>

            </div>
        </div>

    </mat-card>
    <!-- END Complex Event -->

</div>


<ng-template #noVisibleTemplate>
    <fieldset *ngIf="configAllowed" [ngClass]="event.status">
        <app-event-status-detail [event]="event"></app-event-status-detail>
    </fieldset>

    <mat-card-actions class="buttons buttons-right">
        <button *ngIf="configAllowed" mat-fab class="btn-extra" (click)="configItem()">
          <mat-icon>build</mat-icon>
      </button>
    </mat-card-actions>
</ng-template>


<ng-template #loadingTemplate>
    Cargando este evento...
</ng-template>