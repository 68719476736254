<div class="dialog-wrapper">

    <div class="dialog__header">
        <span mat-dialog-title>Filtro por Entidades</span>
    </div>

    <div mat-dialog-content>

        <div [formGroup]="baseItemForm">

            <mat-radio-group aria-label="Selecciona una opción" formControlName="entityOption" class="example-radio-group">

                <mat-radio-button class="radio-button" value="1">Cualquier entidad</mat-radio-button>

                <mat-expansion-panel *ngIf="favEntities$ | async as favEntities" style="width:100%">
                    <mat-expansion-panel-header class="alerted-notice-header" style="padding-left:0px">
                        <mat-radio-button class="radio-button" value="2">
                            Tus entidades favoritas ({{favEntities.length}})
                        </mat-radio-button>
                    </mat-expansion-panel-header>

                    <div *ngFor="let favEntity of favEntities">
                        <img class="option-img" aria-hidden [src]="favEntity.imagePath" height="25">
                        <span>{{favEntity.name}}</span>
                    </div>
                </mat-expansion-panel>

                <mat-radio-button class="radio-button" value="3">Filtrar por una entidad</mat-radio-button>

                <mat-form-field appearance="outline" *ngIf="baseItemForm.controls.entityOption.value === '3'">
                    <mat-label>{{ baseItemName }}</mat-label>
                    <mat-select [placeholder]="baseItemName" formControlName="baseItem" [compareWith]="compareFunction" (selectionChange)="onSelectionChanged($event)">
                        <mat-option *ngFor="let baseItem of baseItems$ | async as baseItems" [value]="baseItem">
                            <img class="option-img" aria-hidden [src]="baseItem.imagePath" height="25">
                            <span>{{baseItem.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </mat-radio-group>

        </div>

    </div>


    <div mat-dialog-actions class="buttons buttons-center">
        <button mat-mini-fab class="btn-1" (click)="onOkClick()" cdkFocusInitial>
            <mat-icon>done</mat-icon>
        </button>

        <button mat-mini-fab class="btn-1" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

</div>
