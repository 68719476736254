<app-section-header *ngIf="showHeader && showSectionHeader" [title]="'La Agenda Rinconera'"></app-section-header>

<div [ngClass]="{'section-wrapper' : showHeader}">

    <app-calendar-header *ngIf="showHeader; else ngSimpleHeaderTemplate" [(view)]="view" [(viewDate)]="viewDate" [entityId]="entityId" (viewDateChange)="fetchEvents()" (viewChange)="fetchEvents()" (filterEntitiesChange)="filterEntities($event)" [locale]="locale">
    </app-calendar-header>

    <ng-template #ngSimpleHeaderTemplate>
        <h3 class="calendar-title">{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
    </ng-template>

    <div *ngIf="events$ | async; else loading; let events">
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [activeDayIsOpen]="activeDayIsOpen" [weekStartsOn]="weekStartsOn" (dayClicked)="dayClicked($event.day)" (eventClicked)="eventClicked($event.event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" (eventClicked)="eventClicked($event.event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" (eventClicked)="eventClicked($event.event)">
            </mwl-calendar-day-view>
        </div>

        <mat-accordion>
            <mat-expansion-panel class="calendar-caption">
                <mat-expansion-panel-header>
                    <mat-panel-title class="calendar-caption__title">
                        Se han encontrado {{ events.length }} eventos {{ infoEventsFooter }}:
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="calendar-caption__item color-date"></div> Día Completo |
                <div class="calendar-caption__item color-datetime"></div> Día y Hora |
                <!--
                    <div class="calendar-caption__item color-deadline"></div>
                -->
                <div class="calendar-caption__item color-provisional"></div> Provisional
            </mat-expansion-panel>
        </mat-accordion>

        <app-section-footer *ngIf="showFooter" [text]="'Se han encontrado ' + events.length + ' eventos ' + infoEventsFooter"></app-section-footer>

    </div>

</div>

<ng-template #loading>
    <div class="loading__container">
        <div class="loading__text">
            cargando agenda
        </div>
        <mat-spinner [diameter]="40" class="loading__spinner"></mat-spinner>
    </div>
</ng-template>
