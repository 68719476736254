<div class="container header-wrapper">
    <div class="large-title">
        <span>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</span>
    </div>

    <div class="title">

        <div class="title__nav buttons">
            <button mat-mini-fab class="btn-1" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
            <mat-icon>arrow_back</mat-icon>
        </button>

            <button mat-mini-fab class="btn-1" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
            HOY
        </button>

            <button mat-mini-fab class="btn-1" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
            <mat-icon>arrow_forward</mat-icon>
        </button>
        </div>

        <div class="title__name">
            <span>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</span>
        </div>


        <div class="title__mode buttons">

            <button *ngIf="(entities.length === 1); else ngAllEntitiesTemplate" mat-mini-fab class="btn-entity" [ngStyle]="applyEntityFilteredStyles()" (click)="gotoEntitiesConfig()">
            </button>

            <ng-template #ngAllEntitiesTemplate>
                <button mat-mini-fab class="btn-1" (click)="gotoEntitiesConfig()">
                    <mat-icon>filter_alt</mat-icon>
                </button>
            </ng-template>

            <button mat-mini-fab class="btn-1" (click)="gotoModeConfig()">
                <mat-icon>tune</mat-icon>
            </button>

            <button mat-mini-fab class="btn-1" (click)="gotoEventSearch()">
                <mat-icon>search</mat-icon>
            </button>
        </div>

    </div>
</div>