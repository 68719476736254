<div *ngIf="calendarEvents.length === 1; else ngTemplateList">

    <div *ngIf="onlyEvent" class="item-container" (click)="openEventClicked(onlyEvent)">

        <app-event-item-detail [eventItem]="onlyEvent" [btnView]="true" [btnClose]="false">
        </app-event-item-detail>

    </div>

</div>


<ng-template #ngTemplateList>
    <div class="items-wrapper">
        <img *ngFor="let calendarEvent of calendarEvents" (click)="openCalendarEventClicked(calendarEvent)" [alt]="calendarEvent.name" [src]="calendarEvent.image" [ngClass]="calendarEvent.baseType" />
    </div>
</ng-template>
