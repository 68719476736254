<app-section-header *ngIf="showHeader" [title]="'La Esquina del tío valero Rinconera'"></app-section-header>

<div class="notices-wrapper">

    <mat-card *ngFor="let notice of notices$ | async; index as i" class="notice-item" routerLink="profile" (click)="gotoNotice(notice)">
        <div class="timestamp">
            <span class="title">Última actualización: </span>
            <span class="data">{{ notice.timestamp }}</span>
        </div>
        <div class="notice-image">
            <img alt="'Tu cuenta personal'" [src]="notice.imagePath" class="img-thumbnail" style="width:200px;height:200px;" />
        </div>
        <mat-card-title>
            <p>{{ notice.name }}</p>
        </mat-card-title>
        <mat-card-content>
            <span>...</span>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary">VER</button>
        </mat-card-actions>
    </mat-card>
</div>
