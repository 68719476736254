<app-section-header [title]="pageTitle"></app-section-header>

<div class="section-wrapper">
    <mat-card class="item-edit" *ngIf="event; else noEventTemplate">

        <div class="row">

            <div class="col-lg-3 col-md-4 col-12" style="text-align: center;">
                <img [src]="eventForm.get('imagePath').value" class="img-thumbnail" (click)="inputImageProfile.click()">
                <input hidden type="file" #inputImageProfile (change)="uploadImage($event)">
                <hr>
                <span (click)="inputImageProfile.click()" class="small">Clica en la imagen para cambiar la foto</span>
            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <form [formGroup]="eventForm" (ngSubmit)="onSaveForm()">

                    <mat-card-content>

                        <fieldset class="form-status-fields" [ngClass]="eventForm.controls.status.value">
                            <legend>Estado del Evento</legend>

                            <div class="row">

                                <mat-form-field class="col-12" appearance="outline">
                                    <mat-label>Estado</mat-label>

                                    <mat-select formControlName="status" [value]="eventForm.get('status')">
                                        <mat-option *ngFor="let status of STATUS" [value]="status">
                                            {{status}}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                                <mat-slide-toggle class="col-12" formControlName="focused">Evento anunciado en portada</mat-slide-toggle>

                                <div *appRole="['ADMIN','SUPER']" class="col-12">
                                    <mat-slide-toggle formControlName="fixed">Evento fijo en portada</mat-slide-toggle>
                                </div>

                                <div *appRole="['ADMIN', 'SUPER']" class="col-12">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Tipo</mat-label>
                                        <mat-select formControlName="eventMode" [value]="eventForm.controls.eventMode.value">
                                            <mat-option *ngFor="let eventMode of EVENT_MODES" [value]="eventMode">
                                                {{ eventMode | eventMode }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>

                            </div>

                        </fieldset>


                        <fieldset class="form-info-fields">
                            <legend>Información del evento</legend>

                            <mat-form-field appearance="outline">
                                <mat-label>Nombre del evento</mat-label>
                                <input matInput type="text" formControlName="name" placeholder="Nombre">
                            </mat-form-field>

                            <mat-form-field appearance="outline" *appRole="['ADMIN', 'SUPER']">
                                <mat-label>URL Sanitizada</mat-label>
                                <input matInput type="text" formControlName="sanitizedUrl" placeholder="URL Sanitizada">
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Categorías</mat-label>
                                <mat-select formControlName="categories" multiple>
                                    <mat-option *ngFor="let category of CATEGORIES" [value]="category">{{category}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Descripción</mat-label>
                                <textarea matInput formControlName="description" placeholder="Descripción"></textarea>
                            </mat-form-field>

                            <div *ngIf="eventForm.controls.description.value">
                                <span class="title">Así se visualizaría la Descripción:</span>
                                <div class="preview" [innerHTML]="eventForm.controls.description.value"></div>
                            </div>

                        </fieldset>

                    </mat-card-content>

                    <mat-card-actions class="buttons">

                        <button mat-fab class="btn-1" [title]="eventForm.valid ? 'Guardar con los datos introducidos' : 'Deshabilitado hasta que los datos sean válidos'" [disabled]="!eventForm.valid" type="submit">
                            <mat-icon>check</mat-icon>
                        </button>

                        <button mat-fab class="btn-1" (click)="gotoEventsOwn()">
                            <mat-icon>list</mat-icon>
                        </button>

                    </mat-card-actions>

                </form>

            </div>
        </div>

    </mat-card>
</div>

<div *ngIf="errorMessage" class="alert-danger">
    {{ errorMessage }}
</div>

<ng-template #noEventTemplate>
    No existe tal evento
</ng-template>