<div class="section-wrapper">

    <mat-card class="item-view" *ngIf="event; else loadingEventTemplate">

        <div class="row">
            <div *ngIf="event.imagePath" class="col-lg-3 col-md-4 col-12" style="text-align: center;">

                <fieldset class="event-item-detail type-other">

                    <legend>Imágenes ({{ event?.images?.length }})</legend>

                    <img [src]="getMediumImage(event.imagePath)" class="centered-and-cropped">

                    <div class="buttons">
                        <button mat-fab class="btn-1" (click)="openEventImageDialog()" color="accent">
                            <mat-icon>collections</mat-icon>
                        </button>
                    </div>

                </fieldset>

            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <mat-card-content class="item-view">

                    <fieldset class="event-item-detail" [ngClass]="event.status">

                        <legend>¿Estado?</legend>

                        <app-event-status-detail [event]="event"></app-event-status-detail>

                        <div class="help-container alert" [ngClass]="{
                          'alert-warning': (event.status === 'EDITING'),
                          'alert-danger': (event.status === 'BLOCKED')
                        }" *ngIf="event.status !== 'VISIBLE'">
                            💡 El botón VER está deshabilitado porque el evento no está en estado
                            <B>VISIBLE</B>.
                        </div>

                        <div class="buttons">
                            <button [disabled]="event.status !== 'VISIBLE'" mat-fab class="btn-1" (click)="gotoItemView()">
                                VER
                            </button>

                            <button mat-fab class="btn-1" (click)="openEventStatusDialog()">
                                <mat-icon>settings</mat-icon>
                            </button>

                            <button *ngIf="event.status==='DELETED'" mat-fab class="btn-1" (click)="deleteForeverItem(event)">
                              <mat-icon>delete_forever</mat-icon>
                          </button>
                        </div>

                    </fieldset>


                    <fieldset id="event-item-title" class="event-item-detail type-what" [ngClass]="event.status">

                        <img src="assets/images/events/config-mode-banner.png" class="ribbon" />

                        <legend>¿Qué?</legend>

                        <app-event-basic-detail [event]="event"></app-event-basic-detail>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openEventBasicDialog()">
                              <mat-icon>edit</mat-icon>
                          </button>
                        </div>

                    </fieldset>


                    <fieldset class="event-item-detail type-other">

                        <legend>¿Cuándo?</legend>

                        <!--
                        <app-event-appointment-detail *ngIf="appointment$ | async as appointment" [showImage]="true" [appointment]="appointment"></app-event-appointment-detail>
                        -->

                        <mat-button-toggle-group *ngIf="appointment$ | async as appointment" [formControl]="shownAsAWholeControl" aria-label="Mostrado como un todo">
                            <mat-button-toggle class="button__when-description" value="true" (change)="onValShownAsAWholeChange($event.value)">{{appointment.description}}</mat-button-toggle>
                            <mat-button-toggle class="button__when-description" *ngIf="event.scheduleItems?.length > 0" value="false" (change)="onValShownAsAWholeChange($event.value)">A partir de la información de sus actos (actualmente {{ event.scheduleItems?.length }})</mat-button-toggle>
                        </mat-button-toggle-group>
                        <span class="text__when-intro">Así se mostrará en el calendario</span>


                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openAppointmentDialog()">
                                <mat-icon>edit_calendar</mat-icon>
                            </button>
                        </div>

                    </fieldset>


                    <!-- AS SPLITTED (NOT SUPEREVENT), THE EVENT CONTAINS SCHEDULE/SESSIONS -->
                    <fieldset *ngIf="event.eventMode === EVENT_MODE_SPLITTED" class="event-item-detail type-other">

                        <legend>¿Cómo? (Sesiones)</legend>

                        <sh-base-items-table [baseItems]="event.scheduleItems" [baseType]="baseType" [modeAdmin]="true" (changeOrderBase)="changeOrderBaseItemFromTable($event)" (addBase)="addBaseItemFromTable($event)" (deleteBase)="deleteBaseItemFromTable($event)" (editBase)="editBaseItemFromTable($event)"
                            (deleteForeverBase)="deleteForeverBaseItemFromTable($event)">
                        </sh-base-items-table>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openScheduleDialog('')" color="accent">
                                <mat-icon>alarm_add</mat-icon>
                            </button>
                        </div>

                    </fieldset>


                    <!-- AS SUPEREVENT, THE EVENT CONTAINS EVENTS-REF -->
                    <fieldset *ngIf="event.eventMode === EVENT_MODE_SUPEREVENT" class="event-item-detail type-other">

                        <legend>¿Cómo? (Programación)</legend>

                        <sh-events-ref-list [eventsRef]="event.eventsRef" [modeAdmin]="true" (deleteRef)="deleteRef($event)" (changeOrderRef)="changeOrderRef($event)" (editRef)="editRef($event)"></sh-events-ref-list>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openEventRefDialog('')" color="accent">
                              <mat-icon>alarm_add</mat-icon>
                          </button>
                        </div>

                    </fieldset>


                    <fieldset class="event-item-detail type-other">

                        <legend>¿Dónde?</legend>

                        <sh-base-items-list [baseItems]="event.placeItems" [modeAdmin]="true" (deleteBase)="deleteBase($event)"></sh-base-items-list>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openPlaceDialog()">
                                <mat-icon>add_location_alt</mat-icon>
                            </button>
                        </div>

                    </fieldset>

                    <fieldset class="event-item-detail type-other">

                        <legend>¿Quiénes?</legend>

                        <sh-base-items-list [baseItems]="event.entityItems" [modeAdmin]="true" [baseItemSelected]="event.entityMain?.id" (deleteBase)="deleteBase($event)"></sh-base-items-list>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openEntityDialog()">
                                <mat-icon>person_add</mat-icon>
                            </button>
                        </div>

                    </fieldset>

                    <fieldset class="event-item-detail type-other">

                        <legend>¿Más info?</legend>

                        <sh-base-items-list *ngIf="linksItemInfo" [baseItems]="linksItemInfo" [modeAdmin]="true" (deleteBase)="deleteBase($event)"></sh-base-items-list>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openLinkItemDialog('', LINK_TYPE_INFO)" color="accent">
                                <mat-icon>playlist_add</mat-icon>
                            </button>
                        </div>

                    </fieldset>


                    <!-- INI Event Memories -->
                    <fieldset class="event-item-detail type-other">

                        <legend>¿Cómo fue?</legend>

                        <sh-base-items-list *ngIf="linksItemReport" [baseItems]="linksItemReport" [modeAdmin]="true" (deleteBase)="deleteBase($event)"></sh-base-items-list>

                        <div class="buttons">
                            <button mat-fab class="btn-1" (click)="openLinkItemDialog('', LINK_TYPE_REPORT)" color="accent">
                                <mat-icon>playlist_add</mat-icon>
                            </button>
                        </div>

                    </fieldset>
                    <!-- INI Event Memories -->


                    <div *appRole="['SUPER']">
                        <fieldset *ngIf="audit" class="event-audit-detail type-other">

                            <legend>¿Audit?</legend>

                            <mat-accordion>
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Auditoría de la administración
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <sh-audit-items-list *ngIf="event.auditItems" [items]="event.auditItems"></sh-audit-items-list>

                                </mat-expansion-panel>
                            </mat-accordion>

                        </fieldset>
                    </div>


                    <div *appRole="['AUTOR', 'ADMIN', 'SUPER']">
                        <fieldset class="event-audit-detail type-other">

                            <legend>¿Interación Social?</legend>

                            <mat-accordion *ngIf="eventSocial">

                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            👏​ Hay {{ eventSocial.nClaps }} aplausos.
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>


                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            ⭐ Como Favorito: {{ socialUsersFav.length }} usuarios
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="items__container" *ngFor="let user of socialUsersFav">
                                        <section class="item">

                                            <div class="item-image" href="#">
                                                <img class="circle" [src]="user.photoURL" [alt]="user.displayName">
                                            </div>
                                            <div class="item-body">
                                                <div class="item-timestamp">
                                                    <span class="icon">🔑​</span> {{user.lastLogin}}
                                                </div>
                                                <div class="item-title-audit">
                                                    <span class="icon">🏷️</span>{{ user.role }}
                                                </div>
                                                <div class="item-heading-audit">
                                                    {{ user.displayName }}
                                                </div>
                                            </div>

                                        </section>
                                    </div>

                                </mat-expansion-panel>


                                <mat-expansion-panel *ngIf="comments$ | async as comments">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            💬 Comentarios: {{ comments.length }}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="items__container" *ngFor="let comment of comments">
                                        <section class="item">
                                            <div class="item-body">
                                                <div class="item-timestamp">{{comment.timestamp}}</div>
                                                <div class="item-heading-audit">{{comment.commentatorDisplayedName}}</div>
                                                <div class="item-name">💬 {{comment.message}}</div>
                                            </div>
                                        </section>
                                    </div>
                                </mat-expansion-panel>


                            </mat-accordion>

                        </fieldset>
                    </div>

                </mat-card-content>

            </div>
        </div>

    </mat-card>

</div>

<ng-template #loadingEventTemplate>
    Cargando este evento...
</ng-template>