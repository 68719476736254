<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>


<mat-dialog-content class="dialog__content" [formGroup]="eventRefForm">

    <fieldset class="dialog__section-wrapper" id="dialog__event-id-wrapper">

        <legend>
            ¿Se corresponde con un evento ya creado?
        </legend>


        <div *ngIf="eventMappedName" class="event-mapped-row">

            <span>
                ✅​ Evento: <b>{{eventMappedName}}</b>
            </span>

            <button mat-mini-fab class="btn-1" (click)="clearMapped()" [disabled]="!eventMapped">
                <mat-icon>clear</mat-icon>
          </button>

        </div>

        <div class="base-container row">
            <mat-form-field *ngIf="!eventMapped" class="event-id-field" appearance="outline">
                <mat-label>Evento Asociado</mat-label>
                <input matInput placeholder="Evento Asociado" formControlName="eventId">
            </mat-form-field>

            <button *ngIf="!eventMapped" mat-mini-fab class="btn-1" (click)="search()" [disabled]="(eventRefForm.controls.eventId.value.length !== 20)" cdkFocusInitial>
                <mat-icon>search</mat-icon>
            </button>
        </div>

    </fieldset>


    <fieldset class="dialog__section-wrapper" id="dialog__images-wrapper">

        <div *ngIf="pictures" class="images-container">

            <div *ngFor="let picture of pictures">
                <img [alt]="picture.path" [src]="picture.path" (click)="onSelectedImage(picture)" [ngClass]="{'selected-image' : (pictureSelected.path === picture.path)}" />
            </div>

        </div>

    </fieldset>


    <fieldset class="dialog__section-wrapper">

        <legend>
            ¿Qué?
        </legend>

        <div class="base-container row">
            <mat-form-field class="name-field" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre del acto" formControlName="name">
            </mat-form-field>
        </div>

        <div class="base-container row">
            <mat-form-field class="description-field" appearance="outline">
                <mat-label>Descripción</mat-label>
                <textarea matInput formControlName="description" placeholder="Descripción"></textarea>
            </mat-form-field>
        </div>

        <div class="base-container row">
            <div class="col-12" *ngIf="eventRefForm.controls.description.value">
                <span class="title">Así se visualizaría:</span>
                <div class="preview" [innerHTML]="eventRefForm.controls.description.value"></div>
            </div>
        </div>

    </fieldset>


    <fieldset class="dialog__section-wrapper">

        <legend>
            ¿Cuándo?
        </legend>

        <div class="col-12">
            <div class="preview" [innerHTML]="'📅 ' + (eventRefForm.controls.dateIni.value) + ' | ' + (eventRefForm.controls.timeIni.value)"></div>
        </div>

        <div class="base-container">

            <mat-form-field class="date-field" appearance="outline">

                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="picker1" placeholder="Fecha" formControlName="dateIni" (dateChange)="onDateIniChange('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="time-field" appearance="outline">

                <mat-slide-toggle formControlName="isTimeIni">Indicar hora de comienzo</mat-slide-toggle>

                <input *ngIf="eventRefForm.controls.isTimeIni.value; else descIniTemplate" matInput type="time" formControlName="timeIni">

                <ng-template #descIniTemplate>
                    <input matInput type="text" formControlName="timeIni">
                </ng-template>

            </mat-form-field>
        </div>

    </fieldset>

    <fieldset class="dialog__section-wrapper">

        <legend>
            ¿Dónde?
        </legend>

        <div class="base-container row">

            <mat-form-field class="col-12" appearance="outline">
                <mat-label>Lugar</mat-label>
                <mat-select placeholder="Lugar" formControlName="place" [compareWith]="compareFunction" (selectionChange)="onSelectionChanged($event)">
                    <mat-option [value]="SECTION_BLANK">
                        SIN ESPECIFICAR
                    </mat-option>
                    <mat-option *ngFor="let place of places$ | async as places" [value]="place">
                        <img class="option-img" aria-hidden [src]="place.imagePath" height="25">
                        <span>{{place.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

    </fieldset>


</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">
    <button mat-fab class="btn-1" (click)="save()" [disabled]="!eventRefForm.valid" type="submit">
        <mat-icon>done</mat-icon>
    </button>
    <button mat-fab class="btn-1" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>