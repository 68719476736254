<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>

<mat-dialog-content class="dialog__content" [formGroup]="baseItemForm">

    <fieldset class="dialog__section-wrapper" id="dialog__images-wrapper">

        <div *ngIf="baseItemSelected.imagePath; else noBaseItemTemplate">
            <img class="dialog__image" [src]="baseItemSelected.imagePath">
        </div>

        <ng-template #noBaseItemTemplate>
            <div>
                <img class="dialog__image" [src]="SECTION_BLANK.imagePath">
            </div>
        </ng-template>

    </fieldset>

    <mat-form-field appearance="outline">
        <mat-label>{{ baseItemName }}</mat-label>
        <mat-select [placeholder]="baseItemName" formControlName="baseItem" [compareWith]="compareFunction" (selectionChange)="onSelectionChanged($event)">
            <mat-option [value]="SECTION_BLANK">
                SIN ESPECIFICAR
            </mat-option>
            <mat-option *ngFor="let baseItem of baseItems$ | async as baseItems" [value]="baseItem">

                <img class="option-img" aria-hidden [src]="baseItem.imagePath" height="25">
                <span>{{baseItem.name}}</span>
                <!-- | <small>Tipo: {{baseItem?.roleDefault}}</small>-->

            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>{{ baseItemDesc }}</mat-label>
        <input matInput placeholder="Descripción" formControlName="baseItemDesc">
    </mat-form-field>

</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">
    <button mat-fab class="btn-1" [disabled]="baseItemSelected.id === '0'" (click)="save()" cdkFocusInitial>
        <mat-icon>done</mat-icon>
    </button>

    <button mat-fab class="btn-1" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>