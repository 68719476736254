<app-section-header [title]="'Creación de eventos en la aplicación'"></app-section-header>

<div class="section-wrapper" *appRole="[ 'LECTOR' ]">
    <div class="alert alert-warning">
        No puedes crear eventos solo siendo lector
    </div>
</div>

<div class="section-wrapper text-center" *appRole="[ 'AUTOR', 'ADMIN', 'SUPER' ]">

    <span class="section-title" *appRole="['AUTOR', 'ADMIN', 'SUPER']">Creación de eventos</span>

    <div class="section-main-info">
        Con tu rol de <span class="role">{{ role }}</span> puedes:
    </div>

    <div class="options-wrapper">

        <div *appRole="[ 'ADMIN', 'SUPER' ]" class="option-item col-12 col-sm-6 col-md-4">
            <mat-card (click)="gotoNewEventFromScratch()">
                <mat-card-title>Evento desde cero</mat-card-title>
                <div class="option-image">
                    <img alt="'Crear evento'" [src]="'assets/images/goto/add-from-scratch.png'" class="img-thumbnail" />
                </div>
            </mat-card>
        </div>

        <div *appRole="['AUTOR', 'ADMIN', 'SUPER']" class="option-item col-12 col-sm-6 col-md-4">
            <mat-card (click)="openEntityDialog()">
                <mat-card-title>Evento de entidad</mat-card-title>
                <div class="option-image">
                    <img alt="'Crear evento'" [src]="'assets/images/goto/add-from-entities.png'" class="img-thumbnail" />
                </div>
            </mat-card>
        </div>

        <div *appRole="['AUTOR', 'ADMIN', 'SUPER']" class="option-item col-12 col-sm-6 col-md-4">
            <div *ngIf="currentUser?.entityDefault">
                <mat-card (click)="createEventFromEntityDefault(currentUser.entityDefault.id)">
                    <mat-card-title>Evento de ...</mat-card-title>
                    <div class="option-image">
                        <img alt="'Crear evento'" [src]="currentUser.entityDefault.imagePath" class="img-thumbnail" />
                    </div>
                </mat-card>
            </div>
        </div>

    </div>
</div>
