<div *ngIf="baseItems.length > 0; else noBaseItemsTemplate">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="baseId">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> No. </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element">{{element.order}}</td>
        </ng-container>

        <ng-container matColumnDef="baseSmallImage">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <img [src]="element.imagePath" class="img-thumbnail small-image" alt="element.name">
            </td>
        </ng-container>

        <ng-container matColumnDef="baseBigImage">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <img [src]="element.imagePath" class="img-thumbnail" alt="element.name">
            </td>
        </ng-container>

        <ng-container matColumnDef="collapsed-info">
            <th mat-header-cell class="td-collapsed-info" *matHeaderCellDef>Infosx</th>
            <td mat-cell class="td-collapsed-info" [class]="element.name" *matCellDef="let element" (click)="editElement(element)">
                <!-- TODO Not admin can't edit, just view -->
                <div class="inside"></div>

                <span class="name">{{ element.name }}</span>
                <span *ngIf="element.extra" class="extra-data">
                    <span class="icon">🕑</span> {{ element.extra }}
                </span>
                <span *ngIf="element.place" class="extra-data">
                    <span class="icon">📍</span> {{ element.place?.name }}
                </span>

                <!--
                <pre>
                  {{ element | json }}
                </pre>
                -->

            </td>
        </ng-container>

        <ng-container matColumnDef="baseSmallName">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Nombre </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="baseBigName">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Nombre </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="baseDescHorario">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Horario </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element"> {{element.extra}} </td>
        </ng-container>

        <ng-container matColumnDef="placeSmallImage">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Lugar </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element">
                <img *ngIf="element.place" [src]="element.place?.imagePath" class="img-thumbnail" [alt]="element.place?.name">
            </td>
        </ng-container>

        <ng-container matColumnDef="baseDesc">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Desc </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="baseTimestamp">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Timestamp </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element"> {{element.timestamp}} </td>
        </ng-container>

        <ng-container matColumnDef="baseLink">
            <th mat-header-cell *matHeaderCellDef> Enlace </th>
            <td mat-cell *matCellDef="let element" (click)="previewItem(element)">{{element.name}}</td>
        </ng-container>


        <!--
        <ng-container matColumnDef="active">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef> Activo </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element">{{(element.active) ? 'Activo' : 'No Activo'}}</td>
        </ng-container>
        -->

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element">
                <span [ngClass]="'status' + ' ' + 'status-' + (element.status)">{{element.status}}</span>
                <!--+{{(element.active) ? 'Activo' : 'No Activo'}}-->
            </td>
        </ng-container>

        <ng-container matColumnDef="baseActions1">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Ver </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element">

                <button mat-icon-button matTooltip="Click to view" class="iconbutton" color="primary" (click)="gotoElement(element)">
                <mat-icon class="btn-1" aria-label="Edit">play_circle_outline</mat-icon>
              </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="baseActions5">
            <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Acciones </th>
            <td mat-cell class="td-extra-lg" *matCellDef="let element">

                <button [disabled]="element.order === 1" mat-icon-button matTooltip="Subir orden" class="iconbutton" (click)="changeOrderElement(element, -1)">
                  <mat-icon class="btn-1" aria-label="Subir Orden">arrow_circle_up</mat-icon>
                </button>

                <button [disabled]="element.order === baseItemsLength" mat-icon-button matTooltip="Bajar orden" class="iconbutton" (click)="changeOrderElement(element, 1)">
                    <mat-icon class="btn-1" aria-label="Bajar Orden">arrow_circle_down</mat-icon>
                </button>

                <button *ngIf="element.active === true; else buttonAddElementTemplate" mat-icon-button matTooltip="Click to Delete" class="iconbutton" (click)="deleteElement(element)">
                    <mat-icon class="btn-1" aria-label="Delete">remove_circle_outline</mat-icon>
                </button>

                <ng-template #buttonAddElementTemplate>
                    <button mat-icon-button matTooltip="Click to Add" class="iconbutton" (click)="addElement(element)">
                        <mat-icon class="btn-1" aria-label="Add">add_circle_outline</mat-icon>
                  </button>
                </ng-template>

                <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" (click)="editElement(element)">
                    <mat-icon class="btn-1" aria-label="Delete">build_circle</mat-icon>
                </button>

                <button *ngIf="!element.active" [disabled]="element.active" mat-icon-button matTooltip="Click to Reomove" class="iconbutton" (click)="deleteForeverElement(element)">
                    <mat-icon class="btn-1" aria-label="Delete Forever">cancel</mat-icon>
                </button>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="(row.active) ? 'table__row table__row--enabled' : 'table__row table__row--disabled'"></tr>
    </table>
</div>


<ng-template #noBaseItemsTemplate>
    Todavía no hay ningún elemento asociado
</ng-template>
