<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>

<mat-dialog-content class="dialog__content" [formGroup]="statusForm">

    <fieldset class="dialog__section-wrapper">

        <legend>
            Estado de visualización del evento
        </legend>

        <mat-form-field appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="status" [value]="statusForm.get('status')">
                <mat-option *ngFor="let status of STATUS" [value]="status">
                    {{ status | status }}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </fieldset>


    <fieldset class="dialog__section-wrapper">

        <legend>
            Modo del Evento
        </legend>

        <mat-form-field appearance="outline">
            <mat-label>Modo</mat-label>
            <mat-select formControlName="eventMode" [value]="statusForm.get('eventMode')">
                <mat-option *ngFor="let eventMode of EVENT_MODES" [value]="eventMode">
                    {{ eventMode | eventMode }}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </fieldset>


    <fieldset class="dialog__section-wrapper">

        <legend>
            Visualización en portada
        </legend>

        <!--
        <div *appRole="['SUPER']" class="col-12">
            <mat-slide-toggle formControlName="active">Evento activo</mat-slide-toggle>
        </div>
        -->

        <div class="base-container row">
            <mat-slide-toggle appearance="outline" formControlName="focused">Anunciar {{ N_DAYS_AHEAD }} días antes</mat-slide-toggle>
        </div>

        <div *appRole="['ADMIN','SUPER']" class="base-container row">
            <mat-slide-toggle *appRole="['ADMIN','SUPER']" appearance="outline" formControlName="fixed">Mostrar fijo en portada</mat-slide-toggle>
        </div>

    </fieldset>

</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">

    <button mat-fab class="btn-1" (click)="save()" cdkFocusInitial>
        <mat-icon>done</mat-icon>
    </button>

    <button mat-fab class="btn-1" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>

</div>