<div class="section-wrapper">

    <mat-card class="item-view" *ngIf="link; else noLinkTemplate">

        <div class="row">
            <div class="col-lg-3 col-md-4 col-12" style="text-align: center;">

                <div *ngIf="link.source.imagePath" class="main-item__image">
                    <img [src]="link.source.imagePath" class="img-thumbnail main-item__image-img">
                </div>

            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <mat-card-content class="item-view">

                    <fieldset class="type-social">

                        <div class="buttons">

                            <!-- <img [src]="'assets/svg/twitter.svg'" (click)="shareLink('twitter')" class="share-button"> -->
                            <!-- <img [src]="'assets/svg/facebook.svg'" (click)="shareLink('facebook')" class="share-button"> -->
                            <img [src]="'assets/svg/whatsapp.svg'" (click)="shareLink('whatsapp')" class="share-button">

                            <span class="share-button">
                            </span>

                        </div>

                    </fieldset>


                    <fieldset>

                        <div class="main-item__title">{{ link.name }}</div>

                        <div class="main-item__categories" *ngIf="link.categories?.length > 0">
                            <mat-chip-list aria-label="Categories">
                                <mat-chip *ngFor="let category of link.categories">{{ category }}</mat-chip>
                            </mat-chip-list>
                        </div>

                        <div class="main-item__description" [innerHTML]="link.description" *ngIf="link.description"></div>

                        <div *ngIf="link.source">
                            <span>Fuente:</span>
                            <h2>
                                <button mat-fab class="btn-1" (click)="gotoUrl()">
                                    <mat-icon>open_in_new</mat-icon>
                                </button> &nbsp; {{ link.source.name }}
                            </h2>
                        </div>

                    </fieldset>

                </mat-card-content>

                <mat-card-actions class="buttons">

                    <button mat-fab class="btn-1" (click)="editItem()">
                        <mat-icon>edit</mat-icon>
                    </button>

                </mat-card-actions>

            </div>
        </div>

    </mat-card>

</div>

<ng-template #noLinkTemplate>
    No existe tal enlace
</ng-template>