<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>

<mat-dialog-content class="dialog__content" [formGroup]="appointmentForm">

    <mat-button-toggle-group class="dialog__section-type-wrapper" appearance="outline" formControlName="appointmentType">
        <mat-button-toggle class="dialog__item-type" *ngFor="let appointmentType of APPOINTMENT_TYPES" (change)="onAppointmentTypeChange(appointmentType)" [value]="appointmentType.type" [aria-label]="appointmentType.icon">
            <span class="icon">{{ appointmentType.icon }}</span>
            <div class="explication" [innerHTML]="appointmentType.explication"></div>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <!--
    <div class="row">
        <div class="col-12 col-md-6" style="color:1px solid red">
            <mat-slide-toggle (change)="onInputChange('allDay')" formControlName="allDay">
                Evento de día completo
            </mat-slide-toggle>
        </div>

        <div class="col-12 col-md-6" style="color:1px solid red">
            <mat-slide-toggle (change)="onInputChange('withEnd')" formControlName="withEnd">
                Indicar fin del evento
            </mat-slide-toggle>
        </div>
    </div>
    -->

    <fieldset class="dialog__section-wrapper">

        <legend>
            ¿Cuándo?
        </legend>

        <mat-form-field class="col-12 col-md-6" appearance="outline">
            <mat-label>Fecha Inicio</mat-label>
            <input matInput [matDatepicker]="picker1" placeholder="Fecha de Inicio" formControlName="dateIni" (dateChange)="onDateIniChange('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-12 col-md-6" *ngIf="( !appointmentForm.controls.allDay.value )" appearance="outline">
            <mat-label>Hora Inicio</mat-label>
            <input matInput type="time" (change)="onInputChange('timeIni')" formControlName="timeIni" placeholder="Hora de Inicio">
        </mat-form-field>

        <mat-form-field class="col-12 col-md-6" appearance="outline" *ngIf="( appointmentForm.controls.withEnd.value )">
            <mat-label>Fecha Fin</mat-label>
            <input matInput [matDatepicker]="picker2" placeholder="Fecha Fin" formControlName="dateEnd" (dateChange)="onDateEndChange('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-12 col-md-6" appearance="outline" *ngIf="( !appointmentForm.controls.allDay.value && appointmentForm.controls.withEnd.value )">
            <mat-label>Hora Fin</mat-label>
            <input matInput type="time" (change)="onInputChange('timeEnd')" name="timeEnd" formControlName="timeEnd" placeholder="Hora de Fin">
        </mat-form-field>

    </fieldset>

    <fieldset>
        <legend>¿Cómo quedaría?</legend>
        <app-event-appointment-detail [showImage]="false" [appointment]="appointmentForm.value"></app-event-appointment-detail>
    </fieldset>

</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">
    <button mat-fab class="btn-1" (click)="save()" cdkFocusInitial>
        <mat-icon>done</mat-icon>
    </button>
    <button mat-fab class="btn-1" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>