<mat-sidenav-container class="sidenav-container">

    <mat-sidenav-content>
        <mat-toolbar class="mat-elevation-z8 sidenav-toolbar" color="primary">

            <span class="logo" routerLink="/">
              <img [src]="'assets/icons/logo.png'" [ngClass]="{'alerted-logo' : (theAlertedNotice$ | async)}" alt='inicio' class="rounded-circle" />
            </span>

            <span class="logo-title" routerLink="/">
              Agenda Rinconera
            </span>

            <span class="logo-title-img" routerLink="/">
                <img [src]="'assets/images/header/logo-title.png'" alt="Agenda Rinconera" />
            </span>

            <span class="fill-space"></span>

            <div *ngIf="(afAuth.authState | async);else login2Template">

                <a *appRole="['AUTOR', 'ADMIN', 'SUPER']" class="header__button header__button-plus" mat-button routerLink="/eventos/new">
                    🆕
                    <span class="text-extra">Evento</span>
                </a>

                <a mat-button class="header__button header__button-logout" (click)="onLogout()">
                      🔐
                      <span class="text-extra">Salir</span>
                  </a>

                <a mat-button class="header__button header__button-profile" routerLink="/admin" *ngIf="afAuth.authState | async as user">
                    <img class="avatar" [src]="user.photoURL || 'assets/default-user.svg'">
                    <span class="text-extra">Menú Personal</span>
                </a>

                <a class="header__button header__button-fav" mat-button routerLink="/eventos/favoritos">
                    <img *ngIf="(userLogged?.favEvents?.length > 0) || (userLogged?.favEntities?.length > 0);else noFavsTemplate" [src]="'assets/images/header/fav-logo-text.png'" alt="Fav" (click)="gotoFavs()" />
                    <!--<span>⭐</span>-->
                    <!--
                    <span class="text-extra" [matBadge]="userLogged?.favEvents?.length">Favs</span>
                    -->
                </a>

            </div>

            <ng-template #noFavsTemplate>
                <img [src]="'assets/images/header/fav-logo-text-alt.png'" alt="Fav" (click)="gotoFavs()" />
            </ng-template>

            <ng-template #login2Template>
                <a mat-button routerLink="/login">
                    🔑
                    <span class="text-extra">Identifícate</span>
                </a>
            </ng-template>

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="'/about'">
                    <i>💡</i>
                    <span>Acerca de esta WebApp</span>
                </a>

                <div *ngIf="afAuth.authState | async; else loginTemplate">

                    <a mat-menu-item [routerLink]="'/admin'">
                        <i>⚙️</i>
                        <span>Administración</span>
                    </a>

                    <a *ngIf="userLogged?.favEvents?.length > 0" mat-menu-item [routerLink]="'/eventos/favoritos'">
                        <i>⭐​</i>
                        <span>Favoritos</span>
                    </a>

                    <a *appRole="['AUTOR', 'ADMIN', 'SUPER']" mat-menu-item [routerLink]="'/eventos/new'">
                        <i>➕</i>
                        <span>Crear evento</span>
                    </a>

                    <a mat-menu-item (click)="onLogout()">
                        <i>🔐</i>
                        <span>Cerrar Sesión</span>
                    </a>

                </div>

                <a mat-menu-item target="_blank" href="http://www.rincondesoto.org/">
                    <i>🏛️</i>
                    <span>Ayuntamiento de Rincón</span>
                </a>

                <a mat-menu-item target="_blank" href="http://perasderincondesoto.com/">
                    <i>🍐</i>
                    <span>Peras de Rincón de Soto</span>
                </a>


                <ng-template #loginTemplate>
                    <a mat-menu-item [routerLink]="'/login'">
                        <i>🔑</i>
                        <span>Acceder con cuenta</span>
                    </a>
                </ng-template>

            </mat-menu>
        </mat-toolbar>

        <ng-content></ng-content>

        <app-footer></app-footer>

    </mat-sidenav-content>
</mat-sidenav-container>