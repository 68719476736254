<div class="dialog__header dialog-comments__header">
    <span mat-dialog-title>{{ title }}</span>
    <button mat-fab class="dialog__button dialog__button-close" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
</div>

<div *appRole="['ADMIN', 'SUPER', 'AUTOR']">
    <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="commentator">
        <mat-radio-button [value]="commentatorAsUser">como {{ commentatorAsUser?.name }}</mat-radio-button>
        <mat-radio-button *ngIf="commentatorAsEntity" [value]="commentatorAsEntity">como {{ commentatorAsEntity?.name }}</mat-radio-button>
        <mat-radio-button *appRole="['SUPER']" [value]="commentatorAsSuper">como {{ commentatorAsSuper?.name }}</mat-radio-button>
    </mat-radio-group>
</div>


<mat-dialog-content *appRole="['ADMIN', 'SUPER']" class="cointainer-form-inline" [formGroup]="commentForm">

    <mat-form-field appearance="outline">
        <mat-label>Mensaje</mat-label>
        <textarea matInput formControlName="message" placeholder="Mensaje"></textarea>
    </mat-form-field>

    <button [disabled]="!commentForm.controls.message.value" mat-mini-fab class="dialog__button" (click)="sendComment()" cdkFocusInitial>
        <mat-icon>add</mat-icon>
    </button>

</mat-dialog-content>


<mat-dialog-content *appRole="['AUTOR']" class="cointainer-form-inline" [formGroup]="commentForm">

    <mat-form-field *ngIf="canCommentAsAutor(); else cantCommentAsAutorTemplate" appearance="outline">
        <mat-label>Mensaje</mat-label>
        <textarea matInput formControlName="message" placeholder="Mensaje"></textarea>
    </mat-form-field>

    <button [disabled]="!commentForm.controls.message.value" mat-mini-fab class="dialog__button" (click)="sendComment()" cdkFocusInitial>
      <mat-icon>add</mat-icon>
  </button>

</mat-dialog-content>


<div class="items__container" *ngFor="let comment of comments$ | async">

    <section class="item">

        <div class="item-image" href="#">
            <img class="circle" [src]="comment.commentatorDisplayedImage" [alt]="commentatorDisplayedName">
        </div>
        <div class="item-body">
            <button *ngIf="canDelete(comment)" class="item-button-delete iconbutton" mat-icon-button matTooltip="Click to Delete" (click)="deleteComment(comment)">
                    <mat-icon class="btn-1" aria-label="Delete">
                        remove_circle_outline
                    </mat-icon>
                </button>
            <div class="item-timestamp">{{comment.timestamp}}</div>
            <div class="item-heading-audit">{{comment.commentatorDisplayedName}}</div>
            <div class="item-text">{{comment.message}}</div>
        </div>

    </section>

</div>


<ng-template #cantCommentAsAutorTemplate>
    <div class="alert alert-warning m-3">
        A pesar de ser autor, no puedes comentar puesto que no tienes permisos sobre las entidades de este evento.
    </div>
</ng-template>
