<div class="wrapper">
    <div class="event-item-detail" *ngIf="appointment; else noAppointmentTemplate">
        <img [src]="'assets/images/appointments/calendar.png'" class="img-thumbnail">
        <div class="info">
            <span class="extra">El evento se celebra</span>
            <span class="name">{{ appointment.description }}</span>
        </div>
    </div>
</div>

<ng-template #noAppointmentTemplate>
    <span class="desc">Aún no se ha asignado horario</span>
</ng-template>
