<app-section-header [title]="'Panel de Configuración de Noticias'"></app-section-header>

<div class="section-wrapper">

    <div class="section-list-options">
        <mat-form-field>
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <div class="buttons">
            <button mat-mini-fab class="btn-1" (click)="addItem()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="id">
                <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Id.</th>
                <td mat-cell class="td-extra-lg" *matCellDef="let element">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Estado</th>
                <td [class]="element.status" mat-cell class="td-extra-lg" *matCellDef="let element">
                    <div class="inside">{{ element.status | status }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="timestamp">
                <th mat-header-cell class="td-extra-lg" *matHeaderCellDef mat-sort-header>Fecha Actualización</th>
                <td mat-cell class="td-extra-lg" *matCellDef="let element">hace {{ element.timestamp }}</td>
            </ng-container>

            <ng-container matColumnDef="sourceImage">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <img *ngIf="element.source" (click)="gotoItem(element)" [src]="element?.source?.imagePath" class="img-thumbnail" alt="element.name">
                </td>
            </ng-container>

            <ng-container matColumnDef="sourceName">
                <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Fuente</th>
                <td mat-cell class="td-extra-lg" *matCellDef="let newsItem">
                    {{ newsItem?.source?.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="collapsed-info">
                <th mat-header-cell class="td-collapsed-info" *matHeaderCellDef mat-sort-header>Info de la noticia</th>
                <td mat-cell class="td-collapsed-info" [class]="item.status" *matCellDef="let item">

                    <button mat-mini-fab class="button-collapsed btn-1" (click)="gotoUrl(item)">
                        <mat-icon>open_in_new</mat-icon>
                    </button>

                    <div (click)="gotoItem(item)" class="inside"></div>

                    <div (click)="gotoItem(item)" style="font-size: smaller;color:gray">
                        actualizado hace {{ item.timestamp }}
                    </div>
                    <div (click)="gotoItem(item)">
                        {{ item.name }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="categories">
                <th mat-header-cell class="td-extra-md" *matHeaderCellDef mat-sort-header>Categorías</th>
                <td mat-cell class="td-extra-md" *matCellDef="let newsItem">{{ newsItem.description }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell class="td-extra-lg" *matHeaderCellDef mat-sort-header>Nombre</th>
                <td mat-cell class="td-extra-lg" *matCellDef="let newsItem">{{ newsItem.name }}</td>
            </ng-container>

            <ng-container matColumnDef="actions4">
                <th mat-header-cell class="td-extra-lg" *matHeaderCellDef>Acciones </th>
                <td mat-cell class="td-extra-lg" *matCellDef="let newsItem">

                    <button mat-icon-button matTooltip="Click to visit news" class="iconbutton" (click)="gotoUrl(newsItem)">
                        <mat-icon class="btn-1" aria-label="View">
                            open_in_new
                        </mat-icon>
                    </button>

                    <button [disabled]="newsItem.status !== 'VISIBLE'" mat-icon-button matTooltip="Click to View" class="iconbutton" (click)="gotoItem(newsItem)">
                        <mat-icon class="btn-1" aria-label="View">
                            remove_red_eye
                        </mat-icon>
                    </button>

                    <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" (click)="editItem(newsItem)">
                        <mat-icon class="btn-1" aria-label="Edit">
                            build_circle
                        </mat-icon>
                    </button>

                    <button mat-icon-button matTooltip="Click to Delete" class="iconbutton" (click)="deleteItem(newsItem)">
                        <mat-icon class="btn-1" aria-label="Delete">
                            delete
                        </mat-icon>
                    </button>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>

    </div>

</div>
