<div class="section-wrapper">

    <mat-card class="item-view" *ngIf="notice; else noNoticeTemplate">

        <div class="row">
            <div class="col-lg-3 col-md-4 col-12" style="text-align: center;">

                <div *ngIf="notice.imagePath" class="main-item__image">
                    <img [src]="getMediumImage(notice.imagePath)" class="img-thumbnail main-item__image-img">
                    <div class="buttons buttons-center">
                        <button class="main-item__image-zoom btn-1" mat-mini-fab aria-label="Ampliar imagen del cartel" (click)="viewImage()">
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                    </div>
                </div>

            </div>

            <div class="col-lg-9 col-md-8 col-12">

                <mat-card-content class="item-view">

                    <fieldset class="type-social">

                        <div class="buttons">

                            <!-- <img [src]="'assets/svg/twitter.svg'" (click)="shareLink('twitter')" class="share-button">  -->
                            <!-- <img [src]="'assets/svg/facebook.svg'" (click)="shareLink('facebook')" class="share-button"> -->
                            <img [src]="'assets/svg/whatsapp.svg'" (click)="shareLink('whatsapp')" class="share-button">

                            <span class="share-button">
                            </span>

                            <div class="fav-button" *ngIf="comments$ | async as comments" [ngSwitch]="comments.length">
                                <button [matBadge]="comments.length" mat-button class="button-image" [style.backgroundImage]="'url(' + BTN_IMG_COMMENTS + ')'" (click)="viewComments(comments.length)"></button>
                            </div>

                        </div>

                    </fieldset>


                    <fieldset>

                        <div class="main-item__title">{{ notice.name }}</div>

                        <div class="main-item__categories" *ngIf="notice.categories?.length > 0">
                            <mat-chip-list aria-label="Categories">
                                <mat-chip *ngFor="let category of notice.categories">{{ category }}</mat-chip>
                            </mat-chip-list>
                        </div>

                        <div class="main-item__description" [innerHTML]="notice.description" *ngIf="notice.description"></div>

                    </fieldset>

                </mat-card-content>

                <mat-card-actions class="buttons">

                    <button *ngIf="configAllowed" mat-fab class="btn-1" (click)="editItem()">
                        <mat-icon>build</mat-icon>
                    </button>

                </mat-card-actions>

            </div>
        </div>

    </mat-card>

</div>

<ng-template #noNoticeTemplate>
    No existe tal aviso
</ng-template>
