<mat-card (click)="gotoNotice()" class="item-card" [class]="notice.status" *ngIf="notice; else loadingTemplate">

    <span *ngIf="notice.status !== 'VISIBLE'" class="item-status" [class]="notice.status">
      {{ notice.status }}
    </span>

    <mat-card-header>
        <mat-card-title>{{ notice.name }}</mat-card-title>
        <mat-card-subtitle>{{ notice.extra }}</mat-card-subtitle>
    </mat-card-header>
    <img [src]="getMediumImage(notice.imagePath)" class="img-thumbnail" [alt]="notice.name">
    <mat-card-content class="item-content">
        <span class="item-timestamp">
            Actualizado hace {{ notice.timestamp }}
        </span>
    </mat-card-content>

    <mat-card-actions>
        <!--
        <app-event-social [event]="event" [userLogged]="userLogged"></app-event-social>
        -->
    </mat-card-actions>

</mat-card>

<ng-template #loadingTemplate>
    Cargando...
</ng-template>