<app-section-header [title]="'Configuración de Favoritos'" showViewMode="true" (viewMode)="setViewMode($event)"></app-section-header>

<div class="section-wrapper">
    <span class="section-subtitle">Entidades favoritas</span>

    <div *ngIf="favEntitiesStr?.length > 0; else noEntitiesTemplate" class="m-3 alert alert-success items__container entities-container" (click)="gotoEntitiesFav()">
        <div class="entities-container__title">Tienes {{ favEntitiesStr?.length }} entidades marcadas como favoritas</div>

        <div>
            <section class="item" (click)="gotoEntitiesFav()">
                <div class="item-image">
                    <img alt="'Entidades Favoritas'" [src]="'assets/images/goto/my-entities.png'" class="square img-thumbnail">
                </div>
                <div class="item-body">Configura tus entidades favoritas para suscribirte a sus eventos</div>
            </section>
        </div>

        <div *ngFor="let entityFav of favEntities$ | async">

            <section *ngIf="entityFav" class="item" (click)="gotoEntity(entityFav)">

                <div class="item-image">
                    <img [src]="entityFav.imageId" class="square img-thumbnail">
                </div>

                <div class="item-body">
                    <div class="item-heading">{{ entityFav.name }}</div>
                </div>

            </section>

        </div>
    </div>

    <ng-template #noEntitiesTemplate>
        <div class="m-3 alert alert-warning entities-container items__container" (click)="gotoEntitiesFav()">
            <div>
                <section class="item" (click)="gotoEntitiesFav()">
                    <div class="item-image">
                        <img alt="'Entidades Favoritas'" [src]="'assets/images/goto/my-entities.png'" class="square img-thumbnail">
                    </div>
                    <div class="item-body">No tienes entidades marcadas como favoritas</div>
                </section>
            </div>
        </div>
    </ng-template>

    <sh-events-table [events]="events" [title]="'Tus Eventos Favoritos'" [viewMode]="viewMode" [displayedColumns]="displayedColumns" (removeFavEvent)="removeFavEvent($event)"></sh-events-table>

</div>