<div class="fav-button" *ngIf="comments$ | async as comments" [ngSwitch]="comments?.length">
    <button [matBadge]="comments?.length" mat-button class="button-image" [style.backgroundImage]="'url(' + BTN_IMG_COMMENTS + ')'" (click)="viewComments(comments?.length)"></button>
</div>

<div class="fav-button" *ngIf="userLogged && itemSocial">

    <button *ngIf="itemSocial.usersFavs?.includes(userLogged.uid); else noFavTemplateButton" [matBadge]="itemSocial?.usersFavs?.length" mat-button class="button-image" [style.backgroundImage]="'url(' + BTN_IMG_FAVORITE_ON + ')'" (click)="setFav(false)"></button>

    <ng-template #noFavTemplateButton>
        <button [matBadge]="itemSocial?.usersFavs?.length" mat-button class="button-image" [style.backgroundImage]="'url(' + BTN_IMG_FAVORITE_OFF + ')'" (click)="setFav(true)"></button>
    </ng-template>

</div>


<div class="fav-button">

    <button *ngIf="applause; else noClapTemplateButton" (click)="clap(applause)" [matBadge]="itemSocial?.nClaps" mat-button class="button-image" [style.backgroundImage]="'url(' + BTN_IMG_CLAP_ON + ')'"></button>

    <ng-template #noClapTemplateButton>
        <button (click)="clap(applause)" [matBadge]="itemSocial?.nClaps" mat-button class="button-image" [style.backgroundImage]="'url(' + BTN_IMG_CLAP_OFF + ')'"></button>
    </ng-template>

</div>