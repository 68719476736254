<div *ngIf="baseItems.length === 1; else ngTemplateList">

    <div *ngIf="baseItems[0] as baseItem" class="item-container" (click)="gotoItem(baseItem)">

        <div class="row">
            <div class="col-4 col-sm-3 col-md-2 col-lg-1" style="text-align: center;">
                <img [src]="baseItem.imagePath" class="img-thumbnail">
            </div>

            <div class="col-8 col-sm-9 col-md-10 col-lg-11">
                <span [ngClass]="(alerted) ? 'alerted-title' : 'title'">{{ baseItem.name }}</span>
                <div class="data">{{ baseItem.timestamp }} 🕑</div>

                <div class="buttons buttons-left">
                    <button mat-mini-fab class="btn-1" (click)="gotoItem(baseItem)">
                      VER
                  </button>
                </div>
            </div>
        </div>

    </div>

</div>


<ng-template #ngTemplateList>
    <div class="items-wrapper">
        <img *ngFor="let item of baseItems" (click)="gotoItem(item)" [alt]="item.name" [src]="item.imagePath" [ngClass]="item.baseType" />
    </div>
</ng-template>
