<div class="stories-wrapper" *ngIf="stories">
    <img *ngFor="let story of stories" (click)="gotoItem(story)" [alt]="story.name" [src]="story.imagePath" [ngClass]="story.baseType" />
</div>

<div class="memories-wrapper" *ngIf="memories">
    <div class="memory-item" *ngFor="let memory of memories">
        <div class="memory-point"></div>
        <div class="memory-name">
            <span class="icon">{{memory.linkItemType}}</span><span class="title">{{memory.name}}</span>
        </div>
        <div class="memory-timestamp">hace {{memory.timestamp}}</div>
        <!--
        <div class="memory-badge" *ngIf="memory.linkItemType">{{memory.linkItemType}}</div>
        -->
        <img class="memory-img" (click)="previewItem(memory)" [alt]="memory.name" [src]="memory.imagePath" [ngClass]="memory.baseType" />
    </div>
</div>
