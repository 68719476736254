<div class="event-item-detail help-container" *ngIf="event.status">

    <div [ngSwitch]="event.status">
        <div *ngSwitchCase="'EDITING'">Este evento está <b>en modo edición</b>, y no puede ser visto por nadie hasta cambiar a modo visible. Tú lo estás viendo por tener un rol en la aplicación que te permite su visualización.</div>

        <div *ngSwitchCase="'BLOCKED'">Este evento está <b>en modo bloqueo</b>, y no puede ser visto por nadie hasta cambiar a modo visible. Tú lo estás viendo por tener un rol en la aplicación que te permite su visualización.</div>

        <div *ngSwitchCase="'DELETED'">Este evento está <b>en modo borrado</b>, y no puede ser visto por nadie hasta cambiar a modo visible. Tú lo estás viendo por tener un rol en la aplicación que te permite su visualización.</div>

        <div *ngSwitchCase="'VISIBLE'">Este evento está <b>en modo visible</b>, por lo que puede ser visto por cualquiera.</div>
    </div>

    <div *ngIf="event.fixed">
        El evento está como fijo en portada.
    </div>

    <div *ngIf="!event.fixed && event.focused">
        El evento sería temporalmente mostrado en portada.
    </div>

    <div *ngIf="!event.fixed && !event.focused">
        El evento no sería mostrado en portada.
    </div>

    <div *ngIf="event.eventMode">
        Está en modo {{ event.eventMode | eventMode }}
    </div>


</div>


<ng-template #ngTemplateNoActive>
    <b>no está activo</b>
</ng-template>