<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>

<mat-dialog-content [formGroup]="eventForm">

    <mat-form-field appearance="outline">
        <mat-label>Nombre del evento</mat-label>
        <input matInput type="text" formControlName="name" placeholder="Nombre">
    </mat-form-field>

    <mat-form-field appearance="outline" *appRole="['ADMIN', 'SUPER']">
        <mat-label>URL Sanitizada</mat-label>
        <input matInput type="text" formControlName="sanitizedUrl" placeholder="URL Sanitizada">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Categorías</mat-label>
        <mat-select formControlName="categories" multiple>
            <mat-option *ngFor="let category of CATEGORIES" [value]="category">{{category}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Descripción</mat-label>
        <textarea matInput formControlName="description" placeholder="Descripción"></textarea>
    </mat-form-field>

    <div *ngIf="eventForm.controls.description.value">
        <span class="title">Así se visualizaría la Descripción:</span>
        <div class="preview" [innerHTML]="eventForm.controls.description.value"></div>
    </div>

</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">
    <button mat-fab class="btn-1" (click)="save()" cdkFocusInitial>
      <mat-icon>done</mat-icon>
  </button>
    <button mat-fab class="btn-1" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
  </button>
</div>
