<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>

<mat-dialog-content class="dialog__content" [formGroup]="linkItemForm">

    <div class="base-container">
        <mat-button-toggle-group class="dialog__section-type-wrapper" appearance="outline" formControlName="linkItemType">
            <mat-button-toggle class="dialog__item-type" *ngFor="let key of LINK_ITEM_TYPES_KEYS" (change)="onLinkItemTypeChange(key)" [value]="key" [aria-label]="key">
                {{ LINK_ITEM_TYPES[key] }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div *ngIf="linkItemForm.controls.linkItemType.value; else noLinkItemTypeTemplate">

        <div class="base-container">

            <mat-form-field hidden="true" class="name-field" appearance="outline">
                <mat-label>Tipo</mat-label>
                <input matInput placeholder="Tipo de enlace" formControlName="name">
            </mat-form-field>

        </div>

        <div class="base-container">

            <mat-form-field class="name-field" appearance="outline">
                <mat-label>Título</mat-label>
                <input matInput placeholder="Título del enlace" formControlName="description">
            </mat-form-field>

        </div>

        <div *ngIf="linkItemForm.controls.linkItemType.value === LINK_ITEM_TYPE_IMAGE" class="images-container">

            <div *ngFor="let picture of pictures">
                <img [alt]="picture.path" [src]="picture.path" (click)="onSelectedImage(picture)" [ngClass]="{'selected-image' : (pictureSelected.path === picture.path)}" />
            </div>

        </div>

        <div *ngIf="linkItemForm.controls.linkItemType.value !== LINK_ITEM_TYPE_IMAGE" class="base-container description-wrapper">
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>URL original</mat-label>
                    <input matInput type="text" formControlName="sourceUrl" placeholder="URL del enlace">
                </mat-form-field>
            </div>
        </div>

        <div class="base-container">

            <mat-form-field class="name-field" appearance="outline">
                <mat-label>Fuente</mat-label>
                <input matInput placeholder="Fuente del enlace" formControlName="sourceName">
            </mat-form-field>

        </div>

    </div>

    <ng-template #noLinkItemTypeTemplate>
        <div class="alert alert-warning mt-3 text-center">
            Selecciona en primer lugar el tipo de enlace
        </div>
    </ng-template>

</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">
    <button [disabled]="!linkItemForm.valid" mat-fab class="btn-1" (click)="save()" cdkFocusInitial>
        <mat-icon>done</mat-icon>
    </button>
    <button mat-fab class="btn-1" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>