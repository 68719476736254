<div class="container" (click)="onBtnViewClick()">

    <div *ngIf="superEventBase">

        <mat-toolbar class="mat-elevation-z8 cointainer__superevent" color="primary" (click)="gotoSuperEvent()">
            <span class="logo">
                <img [src]="superEventBase.imagePath" [alt]="superEventBase.name" class="rounded-circle" />
            </span>

            <span class="logo-title">
                {{ superEventBase.name }}
            </span>
        </mat-toolbar>

    </div>

    <div class="container-event">

        <figure class="container-event__figure" style="text-align: center;">
            <img [src]="eventItem?.imagePath" class="img-thumbnail">
        </figure>

        <div class="container-event__texts">
            <span class="title">{{ eventItem?.name }}</span>
            <div class="data">
                <span class="icon">🕑</span> {{ eventItem?.timestamp | date:'EEEE, d \'de\' MMMM hh:mm' }}
            </div>
            <div class="data" *ngIf="eventItem?.place">
                <span class="icon">📍</span> {{ eventItem.place?.name }}
            </div>

            <div class="desc" [innerHTML]="eventItem?.description">
            </div>

            <div class="buttons">
                <button *ngIf="btnView" mat-fab class="btn-1" (click)="onBtnViewClick()">
                    VER
                </button>

                <button *ngIf="btnClose" mat-fab class="btn-1" (click)="onBtnCloseClick()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>

</div>