<div class="dialog__header">
    <span mat-dialog-title>{{ title }}</span>
</div>

<mat-dialog-content class="dialog__content">

    <div id="dialog__main-image-wrapper" *ngIf="pictureSelected; else noImageTemplate">

        <img class="main-image" [src]="pictureSelected.path">
        <div class="buttons buttons-center">
            <button mat-mini-fab class="btn-extra" (click)="inputMainImage.click()">
                <mat-icon>add_photo_alternate</mat-icon>
            </button> &nbsp;&nbsp;
            <button mat-mini-fab class="btn-extra" (click)="deleteImage()" [disabled]="pictureSelected.path === IMAGE_BLANK">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <input hidden type="file" #inputMainImage (change)="uploadImage($event)">
    </div>

    <ng-template #noImageTemplate>
        <div>
            <img class="main-image" alt="Sin imagen definida" [src]="IMAGE_BLANK"> no-image
        </div>
    </ng-template>

    <div id="dialog__several-images-wrapper" *ngIf="pictures">

        <div class="images-container-text">
            <span>Imágenes Disponibles ({{pictures.length}}):</span>
        </div>

        <div class="images-container">
            <img alt="Sin imagen" (click)="onSelectedImage(PICTURE_BLANK)" [src]="IMAGE_BLANK" [ngClass]="{'selected-image' : (pictureSelected?.path === IMAGE_BLANK)}" />
            <div *ngFor="let picture of pictures">
                <img [alt]="picture.path" [src]="picture.path" (click)="onSelectedImage(picture)" [ngClass]="{'selected-image' : (pictureSelected.path === picture.path)}" />
            </div>
        </div>
    </div>

</mat-dialog-content>

<div mat-dialog-actions class="buttons buttons-center">
    <button mat-fab class="btn-1" (click)="save()" cdkFocusInitial>
        <mat-icon>done</mat-icon>
    </button>
    <button mat-fab class="btn-1" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
  </button>
</div>