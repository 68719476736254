<div class="base-container">

    <div *ngIf="extraBase">

        <mat-toolbar class="mat-elevation-z8" color="primary" (click)="gotoExtraItem()">
            <span class="logo">
                <img [src]="extraBase.imagePath" [alt]="extraBase.name" class="rounded-circle" />
            </span>

            <span class="logo-title">
                {{ extraBase.name }}
            </span>
        </mat-toolbar>

    </div>

    <div class="row">

        <div class="col-3 col-md-4 col-sm-12" style="text-align: center;">
            <img [src]="baseItem.imagePath" class="img-thumbnail">
        </div>

        <div class="col-9 col-md-8 col-sm-12">
            <span class="title">{{ baseItem.name }}</span>
            <div class="data">{{ baseItem.timestamp }} 🕑</div>

            <div class="desc" [innerHTML]="baseItem.description">
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="buttons">
            <button *ngIf="btnView" mat-fab class="btn-1" (click)="onBtnViewClick()">
                VER
            </button>

            <button *ngIf="btnClose" mat-fab class="btn-1" (click)="onBtnCloseClick()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

</div>
