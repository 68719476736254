import { Component, VERSION } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  angularVersion = `Angular ${VERSION.full}`;
  appVersion = environment.appVersion;
  releaseDate = environment.releaseDate;
}
